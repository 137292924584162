export const CardapioLink = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/Bulls+Cardapio.pdf";
export const Cardapio = [
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/1+HIST%C3%93RIA.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/2+COMBOS+_+VEG.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/3+STEAKS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/4+STARTERS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/5+STREET+FOOD.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/6+TO+EAT+WITH+FRINDS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/7+BURGUERS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/8+DESSERTS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/9+CAIPIS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/10+CLASSIC+_+SPECIAL.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/11+GIN+_+BIG+DRINKS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/12+JACK+DANIEL_S+_+SHOTS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/13+CHOPP+_+CERVEJA+_+ARTESANAIS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/14+SOFT+DRINKS+_+DESTILADOS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/15+UNIDADES+_+INFLUENCER.png",
];

export default [{
        href: "/",
        text: "Início"
    },
    {
        href: "/sobre",
        text: "Sobre o Bulls"
    },
    {
        href: "/cardapio",
        text: "Cardápio"
    },
    {
        href: "/ambientes",
        text: "Ambientes"
    },
    {
        href: "/contato",
        text: "Fale Conosco"
    },
    // {
    //     newtab: true,
    //     href: "/pesquisa",
    //     text: "Pesquisa de Satisfação"
    // }
];