import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import CTA from "components/features/ThreeColSimple2.js";
import { SectionHeading as Heading } from "components/misc/Headings.js";

import { FiInstagram as InstagramIcon } from "react-icons/fi";
import LoadingSVG from "../../images/loadings/loading-eclipse-200px.svg";

const ContainerRow = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto pt-10 md:pt-12 bg-black`}
`;
const Container = styled.div`
  ${tw`max-w-full`}
  iframe {
    ${tw`mx-auto max-w-full`}
  }
  .unit-info {
    ${tw`p-4 pr-8 text-white max-w-full`}
    span {
      ${tw`text-primary-100 font-bold`}
    }
    ol {
      ${tw`list-disc`}
      li {
        ${tw`ml-6`}
      }
    }
  }
`;
const LoadingContainer = styled.div`
  ${tw`p-5 md:w-96 md:h-96 flex items-center justify-center`}
  img {
    max-width: 25%;
  }
  span {
    ${tw`text-white text-base`}
  }
`;

const UnitHeading = styled(Heading)
`
  ${tw`text-white text-3xl`}
`;
// filter: drop-shadow(2px 2px 2px rgb(195,0,16))


const CTA_cards = [
  {
    icon: <InstagramIcon size={50} />,
    title: "Reservas via Instagram",
    description:
      "O Bulls trabalha com reservas, exceto às sextas, sábado e vésperas de feriado, quando trabalhamos somente por ordem de chegada !",
    url: "https://www.instagram.com/bullsbeerhouse/",
    target: "_blank",
    linkText: "Manda uma DM !!",
  },
];

//eslint-disable-next-line
export default (props) => {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  return (
    <>
        <CTA cards={CTA_cards} />
        <ContainerRow>
            <Container>
            <UnitHeading >
                Unidade <span tw="p-1 border-b-4 border-primary-500">Parquelândia</span>
            </UnitHeading>
            {loading1 ? (
                <LoadingContainer>
                <img src={LoadingSVG} alt="Loading SVG"/>
                <span>CARREGANDO MAPA</span>
                </LoadingContainer>
            ) : null}
            <iframe
                title="Unidade Parquelândia"
                tw={"p-5 md:w-96 md:h-96"}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127402.59467963254!2d-38.63159718359375!3d-3.737844599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74975bf04de9b%3A0xd23a2fa8c58ff7ab!2sBulls%20Beer%20House%20Parquel%C3%A2ndia!5e0!3m2!1spt-BR!2sbr!4v1652891373796!5m2!1spt-BR!2sbr"
                style={{ border: 0 }}
                onLoad={() => setLoading1(false)}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <section className="unit-info">
                <p><span>Endereço:</span> Rua Gustavo Sampaio, 800A, Parquelândia</p>
                <br></br>
                <p><span>Horário de Funcionamento:</span></p>
                <ol>
                <li><p><span>Terça e Quarta</span> 16h às 00h</p></li>
                <li><p><span>Quinta</span> 16h às 01h</p></li>
                <li><p><span>Sexta</span> 16h às 02h</p></li>
                <li><p><span>Sábado</span> 15h às 02h</p></li>
                <li><p><span>Domingo</span> 15h às 00h</p></li>
                </ol>
            </section>
            </Container>

            <Container>
            <UnitHeading >
                Unidade <span tw="p-1 border-b-4 border-primary-500">Aldeota</span>
            </UnitHeading>
            {loading2 ? (
                <LoadingContainer>
                <img src={LoadingSVG} alt="Loading SVG"/>
                <span>CARREGANDO MAPA</span>
                </LoadingContainer>
            ) : null}
            <iframe
                title="Unidade Aldeota"
                tw={"p-5 md:w-96 md:h-96"}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127402.59467963254!2d-38.63159718359375!3d-3.737844599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c7494dbd52e987%3A0xab3e271c84cf93ab!2sBulls%20Beer%20House%20Aldeota!5e0!3m2!1spt-BR!2sbr!4v1652873660037!5m2!1spt-BR!2sbr"
                style={{ border: 0 }}
                onLoad={() => setLoading2(false)}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <section className="unit-info">
                <p><span>Endereço:</span> Rua Coronel Jucá, 700, Aldeota</p>
                <br></br>
                <p><span>Horário de Funcionamento:</span></p>
                <ol>
                <li><p><span>Terça e Quarta</span> 16h às 00h</p></li>
                <li><p><span>Quinta</span> 16h às 01h</p></li>
                <li><p><span>Sexta</span> 16h às 02h</p></li>
                <li><p><span>Sábado</span> 15h às 02h</p></li>
                <li><p><span>Domingo</span> 15h às 00h</p></li>
                </ol>
            </section>
            </Container>
        </ContainerRow>
    </>
  );
};
