import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const Column = tw.div `flex flex-col items-center`;

const OPAContainer = tw.dl `max-w-6xl relative w-screen py-12 -mt-12 px-2`;
const ContentSection = motion(
    tw.div`
    z-0 cursor-pointer select-none mt-12 px-8 sm:px-10 py-5 sm:py-4
    rounded-lg text-white hover:text-primary-500 border-t-2
    border-primary-900 transition duration-300
    `
);
// const ContentSection = motion(tw.div `z-0 cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`);
const OPASection = tw.dt `flex justify-between items-center`;
const OPASectionText = tw.span `text-lg lg:text-xl font-semibold`;
const OPASectionToggleIcon = motion(styled.span `
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const OPASectionContent = motion(tw.dd`pointer-events-auto text-sm sm:text-base leading-relaxed bg-black mt-16 mb-16`);


//eslint-disable-next-line
export default (props) => {
  return (
    <Container>
        <Column>
            <OPAContainer>
                {props.sections.map((section, index) => {

                    return (
                        <ContentSection
                            id={section.id}
                            key={index}
                            ref={props.sectionManager.sectionRefs[section.id].ref}
                            variants={{
                                // collapsed: {  },
                                // open: { backgroundColor: "rgb(0,0,0)", border: "1px solid #CD1618", color: "#CD1618" }
                            }}
                            initial="collapsed"
                            animate={props.sectionManager.activeSectionIndex === index ? "open" : "collapsed"}
                            transition={{ duration: 0.01, ease: [0.04, 0.62, 0.23, 0.98] }}
                            onClick={(event) => {
                                if(event.target.classList.contains("OPA")){
                                    props.sectionManager.toggleSection(index);
                                }
                            }}
                            className="OPA"
                        >
                            <OPASection className="OPA">
                                <OPASectionText className="OPA">
                                    {section.title}
                                </OPASectionText>
                                <OPASectionToggleIcon
                                    variants={{
                                        collapsed: { rotate: 0 },
                                        open: { rotate: -180 }
                                    }}
                                    initial="collapsed"
                                    animate={props.sectionManager.activeSectionIndex === index ? "open" : "collapsed"}
                                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    className="OPA"
                                >
                                    <ChevronDownIcon className="OPA" />
                                </OPASectionToggleIcon>
                            </OPASection>
                            <OPASectionContent
                                variants={{
                                open: { display: 'block', opacity: 1, height: "auto" },
                                collapsed: { display: 'none' , opacity: 0, height: 0 }
                                }}
                                initial="collapsed"
                                animate={props.sectionManager.activeSectionIndex === index ? "open" : "collapsed"}
                                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                className={`${section.action ? "OPA-action" : "OPA"}`}
                            >
                                {section.content}
                            </OPASectionContent>
                        </ContentSection>
                    )}
                )}
            </OPAContainer>
        </Column>
    </Container>
  );
};